<template>
  <v-container fluid>
    <PageToolbar
      title="Timbrados"
      icon="mdi-newspaper-variant-outline"
      dark
      voltar
    />
    <v-card>
      <v-card-title class="tocs_gray_1">
        <v-text-field
          style="max-width: 400px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        >
        </v-text-field>
        <v-spacer class="d-none d-sm-flex"></v-spacer>
        <DialogTimbrado
          v-if="dialogTimbrado"
          :dialogTimbrado.sync="dialogTimbrado"
          :edit="edit"
          :item="timbrado"
          @fetch-timbrados="getTimbrados"
        />
        <v-btn
          color="button_1"
          @click="addTimbrado"
          class="white--text mt-4 mt-sm-0"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          v-if="!loading"
          :headers="headers"
          :items="timbrados"
          :search="search"
          :items-per-page="10"
          :item-class="row_classes"
          :sort-by="['created_at']"
          :sort-desc="true"
          class="data-tables data-tables__row-click"
          @click:row="goToTimbrado"
        >
          <template v-slot:item.data_inicio="{ item }">
            {{ item.data_inicio | dateFormat("dd/MM/yyyy") }}
          </template>
          <template v-slot:item.data_fim="{ item }">
            {{ item.data_fim | dateFormat("dd/MM/yyyy") }}
          </template>
          <template v-slot:item.tipo="{ item }">
            {{ item.tipo == 1 ? "Fatura" : "Nota de Crédito" }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { fetchTimbrados } from "@/api/timbrado/timbrado.js";
import { format } from "date-fns";

export default {
  name: "Timbrado",

  components: {
    DialogTimbrado: () => import("./components/DialogTimbrado.vue"),
  },

  data() {
    return {
      dialogTimbrado: false,
      timbrados: [],
      timbrado: {},
      loading: false,
      timbrado_original: {},
      search: "",
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.$tc("global.tipo"),
          value: "tipo",
        },
        {
          text: this.$tc("global.datainicio"),
          value: "data_inicio",
        },
        {
          text: this.$tc("global.datafim"),
          value: "data_fim",
        },

        {
          text: this.$tc("global.sequenciainicio"),
          value: "sequencia_inicio",
        },
        {
          text: this.$tc("global.sequenciafim"),
          value: "sequencia_fim",
        },
        {
          text: this.$tc("global.ultimanumeracaoemitida"),
          value: "sequencia_atual",
        },
        {
          text: this.$tc("global.ponto"),
          value: "ponto",
        },
        {
          text: this.$tc("global.timbrado"),
          value: "timbrado",
        },
      ];
    },
  },
  methods: {
    row_classes(item) {
      let dataNow = "";
      dataNow = format(new Date(), "yyyy-MM-dd");
      if (item.data_fim < dataNow) {
        return "tocs_red--text";
      }
    },


    addTimbrado() {
      this.dialogTimbrado = true;
      this.edit = false;
    },

    goToTimbrado(item) {
      this.timbrado = { ...item };
      this.dialogTimbrado = true;
      this.edit = true;
    },

    getTimbrados() {
      this.loading = true;

      fetchTimbrados()
        .then((response) => {
          this.timbrados = response;
          this.timbrado_original = { ...response };
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },

  created() {
    this.getTimbrados();
  },
};
</script>

<style></style>
